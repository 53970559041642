import {
  ErrorPage as DsErrorPage,
  ErrorPageProps as DsErrorPageProps,
} from '@loveholidays/design-system';
import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { setPageTypeClient, useAppContext } from '@Contexts/contexts';
import { useQueryParams } from '@Core/helpers/url';
import {
  ClientEventOptions,
  useClientEventTracking,
} from '@Core/tracking/hooks/useClientEventTracking';
import { useIdentifyTracking } from '@Core/tracking/hooks/useIdentifyTracking';
import { sendEvent } from '@Core/tracking/sendEvent';
import {
  ErrorEventType,
  ErrorWebEventTrackingObject,
  TrackingEvent,
  WebEventCategory,
} from '@Core/tracking/types';
import { isCheckoutPages } from '@Pages/generic/GenericPage';
import { useGenericPageStore } from '@Stores/StoreContext';
import { usePageViewTracking } from '@Tracking';

export interface ErrorPageProps extends DsErrorPageProps {
  title: string;
  errorType?: ErrorEventType;
  errorAction?: ErrorEventType;
  errorStatus?: '404' | '500';
}

export const ErrorPage: React.FC<React.PropsWithChildren<ErrorPageProps>> = ({
  title,
  subtitle,
  actions,
  content,
  errorAction = ErrorEventType.errorPageShown,
  errorType = ErrorEventType.errorPageShown,
  errorStatus,
  children,
}) => {
  const { pageType: pageTypeQueryParam = '' } = useQueryParams();
  const currentPageType = useGenericPageStore(({ pageContent }) => pageContent.pageType);
  let errorActionToUse = errorAction;
  let errorTypeToUse = errorType;

  let clientEventPayload: ClientEventOptions = {
    type: errorType,
    subType: errorAction,
  };

  if (isCheckoutPages(currentPageType)) {
    clientEventPayload = {
      type: ErrorEventType.checkoutErrorPageShown,
      subType: ErrorEventType.checkoutErrorPageShown,
      pageType: pageTypeQueryParam, // used to report where the user has come from
    };
    errorActionToUse = ErrorEventType.checkoutErrorPageShown;
    errorTypeToUse = ErrorEventType.checkoutErrorPageShown;
  }

  const trackIdentify = useIdentifyTracking();
  const trackPageview = usePageViewTracking();
  const {
    site: { pathPrefix, brand },
  } = useAppContext();
  const { pathname } = useLocation();

  const sendClientEvent = useClientEventTracking();

  useEffect(() => {
    sendEvent<ErrorWebEventTrackingObject>({
      event: TrackingEvent.webEvent,
      category: WebEventCategory.error,
      action: errorActionToUse,
      errorType: errorTypeToUse,
    });

    sendClientEvent(clientEventPayload);

    if (errorStatus) {
      setPageTypeClient(`${pathPrefix}${pathname}`, 'other');
      trackIdentify();
      trackPageview({ status: errorStatus });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorStatus, pathPrefix, pathname]);

  const brandName = brand === 'holidaypirates' ? 'holidaypirates' : 'standard';

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <DsErrorPage
        title={title}
        subtitle={subtitle}
        content={content}
        actions={actions}
        brand={brandName}
      >
        {children}
      </DsErrorPage>
    </Fragment>
  );
};

export default ErrorPage;
